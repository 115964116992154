<template>
      <main class="vacancy">
        <section class="section section1">
          <div class="container__block">
            <h2>В нашей команде - <br/> <span>только вперед!</span></h2>
            <div class="containter__block-desc">
              “Армед” - крупный российский бренд медицинского оборудования с 25-летней историей. 
              В нашей команде более 500 сотрудников, и нас объединяет желание помогать людям укреплять и сохранять здоровье. 
              Мы стремительно внедряем новые технологии на рынок и следим за качеством предоставляемых услуг
            </div>
          </div>
          <div class="container__down">
            <div class="container__down-row">
              <div class="row__item">
                <div class="item__img">
                  <img src="../assets/img/vacancy/1.png" alt="">
                </div>
                <div class="item__desc">
                  <span>Современное</span> рабочее пространство
                </div>
              </div>
              <div class="row__item">
                <div class="item__img">
                  <img src="../assets/img/vacancy/2.png" alt="">
                </div>
                <div class="item__desc">
                  Забота о <span>планете</span>
                </div>
              </div>
              <div class="row__item">
                <div class="item__img">
                  <img src="../assets/img/vacancy/3.png" alt="">
                </div>
                <div class="item__desc">
                  Возможности для <span>карьерного роста</span> 
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section section2">
          <div class="container__block">
            <div class="block__item" v-for="(job, k) in vacancies" :key="k">
              <div class="item__content">
                <div class="title">
                  {{ job.NAME }}
                </div>
                <div class="desc">
                  {{ job.PROPS.chart.VALUE }}
                </div>
                <div class="content">
                  {{ job.PROPS.content.VALUE }}
                </div>
                <div class="price-button">
                  <div class="price">{{ job.PROPS.salary.VALUE }}</div>
                  <router-link @click.prevent="scrollToTop" :to="{ name: 'vacancy', params: {id: job.ID}}" class="button">Откликнуться</router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
    </main>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MainVacancy',
  components: {

  },
  data: () => ({
    vacancies: [],
    jobs: [
      {
        id: 1,
        title: 'Грузчик',
        desc: 'График: 5/2 с понедельника по пятницу с 09:00 до 18:00',
        content: 'Погрузочно-разгрузочные работы, сбор товара по накладным',
        price: 'от 30 000 ₽'
      },
      {
        id: 2,
        title: 'Уборщик',
        desc: 'График: 5/2 с понедельника по пятницу с 09:00 до 18:00',
        content: 'Уборщик производственных и служебных помещений',
        price: '30 000 ₽'
      },
      {
        id: 3,
        title: 'Кладовщик',
        desc: 'График: 5/2 с понедельника по пятницу с 09:00 до 18:00',
        content: 'Прием и отгрузка товара, организация складского учета',
        price: '50 000 ₽'
      },
      {
        id: 4,
        title: 'Сборщик',
        desc: 'График: 5/2 с понедельника по пятницу с 09:00 до 18:00',
        content: 'Сборка электроприборов: сборка плат, установка деталей в корпуса, упаковка',
        price: 'от 30 000 ₽'
      },
      {
        id: 5,
        title: 'Швея/закройщик',
        desc: 'График: 5/2 с понедельника по пятницу с 09:00 до 18:00',
        content: 'Пошив и раскрой чехлов.',
        price: '51 724 ₽'
      }
    ]
  }),
  created() {
    this.getVacancies();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(({
        top: 0,
        behavior: 'smooth',
      }));
    },
    getVacancies() {
      axios
        .get('https://www.armed.ru/personal/npcarmed/vacancies/')
        .then((response) => {
          let vacancies = response.data;

          this.vacancies = vacancies;
        });
    },
  }
}
</script>
