import { createApp } from 'vue'
import "./assets/styles/main.scss"
import App from './App.vue'
import {createRouter, createWebHistory} from 'vue-router'
import MainIndex from './components/MainIndex.vue';
import MainVacancy from './components/MainVacancy.vue';
import MainVacancyId from './components/MainVacancyId.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';

const app = createApp(App);

const routes = [
  { path: '/', component: MainIndex },
  { path: '/vacancy', component: MainVacancy },
  { path: '/vacancy/:id', name: 'vacancy', component: MainVacancyId },
  { path: '/privacy-policy/', component: PrivacyPolicy},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

app.use(router);

app.mount('#app');
