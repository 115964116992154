<template>
  <div class="container">
    <div class="section__row">
      <div class="section__content">
        <h2 class="section__subtitle">Вы поставщик металла / пластика / картона / пенопласта и прочих материалов?</h2>
        <div class="section__text">Оставьте контакты и мы с Вами свяжемся</div>
        <div class="section__form-row">
          <input class="section__input" type="text" placeholder="Имя" v-model="name">
          <input class="section__input" type="tel" placeholder="Телефон" v-model="phone">
        </div>
        <div class="section__form-row">
          <div class="selection__row">
            <select class="section__select" v-model="type">
              <option disabled selected>Вид деятельности</option>
              <option value="Поставщик материалов для продукции">Поставщик материалов для продукции</option>
              <option value="Поставщик материалов для упаковки">Поставщик материалов для упаковки</option>
              <option value="Просто хочу сотрудничать">Просто хочу сотрудничать</option>
              <option value="Другое">Другое</option>
            </select>
            <img class="section__select-icon" src="../assets/img/11.svg" />
          </div>
          <input class="section__input" type="text" placeholder="Отрасль" v-model="sector">
        </div>
        <div class="row__buttons" v-if="!isUserMobile">
          <button class="page__button" @click="sendForm">Отправить контакты</button>
          <label class="checkbox js-privacy-policy" style="margin-right: 44px;">
            <input type="checkbox" v-model="policyPrivacy" class="checkbox__input">
            <div class="checkbox__img"><svg class="checkbox__img-pic">
              <use xlink:href="../assets/img/sprite.svg#check_box_outline_blank"></use></svg> <svg class="checkbox__img-pic"><use xlink:href="../assets/img/sprite.svg#check_box"></use></svg>
            </div> 
              <div class="checkbox__text">
                  Я прочитал <router-link @click.prevent="scrollToTop" to="/privacy-policy/" class="header__phone link">Политику безопасности</router-link> и согласен с условиями безопасности и обработки данных
              </div>
          </label>
        </div>
        <div class="row__buttons" style="flex-direction: column;" v-else>
          <label class="checkbox js-privacy-policy" style="margin-right: 44px;">
            <input type="checkbox" v-model="policyPrivacy" class="checkbox__input">
            <div class="checkbox__img"><svg class="checkbox__img-pic">
              <use xlink:href="../assets/img/sprite.svg#check_box_outline_blank"></use></svg> <svg class="checkbox__img-pic"><use xlink:href="../assets/img/sprite.svg#check_box"></use></svg>
            </div> 
              <div class="checkbox__text">
                  Я прочитал <router-link @click.prevent="scrollToTop" to="/privacy-policy/" class="header__phone link">Политику безопасности</router-link> и согласен с условиями безопасности и обработки данных
              </div>
          </label>
          <button class="page__button" @click="sendForm">Отправить контакты</button>
        </div>
      </div>
      <div class="section__img"><img class="section__img-pic" src="../assets/img/1001_n.png"></div>
    </div>
  </div>
</template>


<script>
  import axios from 'axios';

  export default {
    name: 'ProvidersNPC',
    data: () => ({
      name: '',
      phone: '',
      type: 'Вид деятельности',
      sector: '',
      policyPrivacy: false,
      isUserMobile: false
    }),
    created() {
      this.isUserMobile = this.isMobile();
    },
    methods: {
      scrollToTop() {
        window.scrollTo(({
          top: 0,
          behavior: 'smooth',
        }));
      },
      sendForm() {
        if (this.name.length < 3) {
          alert('Укажите ваше имя.');

          return false;
        }

        if (this.phone.length < 6) {
          alert('Укажите ваш телефон');

          return false;
        }

        if (this.type.length < 3 || this.type == 'Вид деятельности') {
          alert('Выберите тип деятельности');

          return false;
        }

        if (this.sector.length < 3) {
          alert('Укажите отрасль');

          return false;
        }

        if (!this.policyPrivacy) {
          alert('Подтвердите согласие с условиями безопаности и обработки данных');

          return false;
        }

        axios({
          method: 'post',
          url: 'https://www.armed.ru/personal/npcarmed/',
          headers: { 'content-type': 'application/form-data' },
          data: {
            token: 'XiHU2Kg4ZEQ4nQRH4oRFZrM98tz',
            name: this.name,
            phone: this.phone,
            type: this.type,
            sector: this.sector
          },
        }).then(function (response) {
          console.log(response);
        });
      },
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>
