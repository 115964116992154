<template>
  <main class="vacancy">
    <section class="section section__job">
      <div class="job__container">
        <div class="job__title"><h2>{{ vacancy.NAME }}</h2></div>
        <div class="job__desc">{{ vacancy.PROPS?.job_chart.VALUE }}</div>
        <div class="job__content">
          <div class="block">
            <div class="row__item" v-for="item in vacancy.HTML_DATA" :key="item.ID">
              <div class="item__title">{{ item.NAME }}</div>
              <div class="item__list">
                <div class="list__text" v-for="(v, k) in item.VALUE" :key="k">{{ v }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>

import axios from 'axios';

export default {
  name: 'MainVacancy',
  data: () => ({
    id: -1,
    vacancy: {}
  }),
  created() {
    this.id = this.$route.params.id;

    this.getVacancyByID(this.id);
  },
  methods: {
    getVacancyByID(id) {
      axios
        .get('https://www.armed.ru/personal/npcarmed/vacancies/?id=' + id)
        .then((response) => {
          let vacancy = response.data[0];

          this.vacancy = vacancy;
        });
    },
  }
}
</script>
