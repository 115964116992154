<template>
    <section class="policy-privacy">
        <div class="container">
            <div class="document" @click.prevent="openPrivacyPolicy">
                <div class="document__pict">
                    <div class="document__wm">
                        <img src="../assets/img/wm.png" alt="">
                    </div>
                    <img src="../assets/img/document.png" alt="">
                </div>
                <div class="document__desc">Политика защиты и обработки персональных данных</div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'PrivacyPolicy',
    components: {

    },
    data: () => ({

    }),
    created() {
        console.log(this.$route);
    },
    methods: {
        scrollToTop() {
            window.scrollTo(({
                top: 0,
                behavior: 'smooth',
            }));
        },
        openPrivacyPolicy() {
            window.open('/Политика защиты и обработки перс данных -НПЦ АРМЕД (1).pdf', '_blank');
        }
    }
}
</script>
