<template>
      <main class="main">
        <section class="section section1">
          <div class="section__bg"><img class="section__bg-pic" src="../assets/img/bg1_n.png" alt="НПЦ МТ Армед"></div>
          <div class="container container_bg">
            <div class="page__content">
              <h1 class="page__title">НПЦ МТ АРМЕД</h1>
              <div class="page__text">Открываем новую эру отечественного производства медицинского оборудования</div><a class="page__button" target="_blank" href="https://www.youtube.com/watch?v=bvZYt5DXj9s">Смотреть видео</a>
            </div>
          </div>
        </section>
        <section class="section section2">
          <div class="container">
            <div class="section__content">
              <h2 class="section__title">Научно-производственный центр медицинской техники “Армед”</h2>
              <div class="section__list">
                <div class="section__item"><img class="section__item-img" src="../assets/img/11.png">
                  <div class="section__item-text">Медицинское оборудование отечественного производства</div>
                </div>
                <div class="section__item"><img class="section__item-img" src="../assets/img/12.png">
                  <div class="section__item-text">С 2013 года на российском рынке</div>
                </div>
                <div class="section__item"><img class="section__item-img" src="../assets/img/13.png">
                  <div class="section__item-text">Сделано в России</div>
                </div>
                <div class="section__item"><img class="section__item-img" src="../assets/img/141.png">
                  <div class="section__item-text">Бренд, которому доверяют миллионы</div>
                </div>
                <div class="section__item"><img class="section__item-img" src="../assets/img/15_n.png">
                  <div class="section__item-text">Свои низкие цены независимо от курса валют</div>
                </div>
              </div>
              <div class="section__text">НПЦ МТ “Армед” разрабатывает и производит товары для защиты и поддержки здоровья, спасения жизни и реабилитации</div>
            </div>
          </div>
        </section>
        <div class="section section-wrap">
          <section class="section section3 section3_1">
            <div class="section__bg"><img class="section__bg-pic" src="../assets/img/bg2_n.png"></div>
            <div class="container container_bg">
              <div class="section__content">
                <div class="section__blur"></div>
                <h2 class="section__title">Крупнейший в России производитель</h2>
                <div class="section__text">рециркуляторов закрытого типа для разнопрофильных объектов</div>
              </div>
            </div>
          </section>
          <section class="section section3 section3_2">
            <div class="section__bg"><img class="section__bg-pic" src="../assets/img/bg3_n.png"></div>
            <div class="container container_bg">
              <div class="section__content">
                <div class="section__blur"></div>
                <h2 class="section__title">Динамично растущее производство во Владимирской области</h2>
                <div class="section__text">Общая площадь в собственности более 2Га. 12 линий сборки разнопрофильного оборудования</div>
              </div>
            </div>
          </section>
          <section class="section section3 section3_3">
            <div class="section__bg"><img class="section__bg-pic" src="../assets/img/bg4_n.png"></div>
            <div class="container container_bg">
              <div class="section__content">
                <div class="section__blur"></div>
                <h2 class="section__title">Большая команда профессионалов</h2>
                <div class="section__text">Более 120 инженеров-конструкторов, технологов, менеджеров и сборщиков</div>
              </div>
            </div>
          </section>
          <section class="section section3 section3_4">
            <div class="section__bg"><img class="section__bg-pic" src="../assets/img/bg5_n.png"></div>
            <div class="container container_bg">
              <div class="section__content">
                <div class="section__blur"></div>
                <h2 class="section__title">Ежедневные объемы производства</h2>
                <div class="section__text">Более 1 500 медицинских изделий изготавливаем ежедневно</div>
              </div>
            </div>
          </section>
          <section class="section section3 section3_5">
            <div class="section__bg"><img class="section__bg-pic" src="../assets/img/bg6.jpg"></div>
            <div class="container container_bg">
              <div class="section__content">
                <div class="section__blur"></div>
                <h2 class="section__title">Собственная метрологическая лаборатория</h2>
                <div class="section__text">Оперативная поверка медицинских приборов</div>
              </div>
            </div>
          </section>
        </div>
        <section class="section section4">
          <div class="container">
            <h2 class="section__title">Наша продукция</h2>
            <div class="section__row">
              <div class="section__content">
                <div class="section__subtitle">Рециркуляторы закрытого типа “Армед”</div>
                <div class="options__list">
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">1-, 2- и 3-ламповые приборы</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Металлический корпус</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Надежная конструкция</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Простое управление</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Низкий уровень шума вентиляторов</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Производительность от 30 до 100 куб.м</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Оптимальный функционал</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Безопасная работа в присутствии людей</div>
                  </div>
                </div>
              </div>
              <div class="section__img"><img class="section__img-pic" src="../assets/img/41_n.png"></div>
            </div>
            <div class="section__list">
              <div class="section__item" v-for="(product, key) in products" :key="key">
                <a class="section__card" :href="product.url" target="_blank">
                  <div class="section__card-decor">
                    <img class="section__card-decor-pic" src="../assets/img/basket.png">
                  </div>
                  <img class="section__card-img" :src="product.thumbnail" />
                  <div class="section__card-text" v-html="product.title"></div>
                  <div class="section__card-price">
                    <del>{{ product['old_price'] }}</del>
                    <ins>{{ product.price }}</ins>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class="section section4">
          <div class="container">
            <h2 class="section__title">Новинка 2022</h2>
            <div class="section__row" :class="{'desktop': !isUserMobile}">
              <div class="section__content">
                <div class="section__subtitle">Армед 115-22 M Slim</div>
                <div class="options__list">
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Узкий металлический корпус</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">1 УФ-лампа мощностью 15Вт</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Фильтр грубой очистки воздуха</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Вертикальная установка без подставки</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Современный лаконичный дизайн</div>
                  </div>
                </div>
              </div>
              <div class="section__img"><img class="section__img-pic" src="../assets/img/61_n.png"></div>
            </div>
          </div>
        </section>
        <section class="section section5">
          <div class="container">
            <h2 class="section__title">Эффективность рециркуляторов научно подтверждена</h2>
            <div class="section__row"><a class="section__img" href="https://npcarmed.ru/assets/img/effectiveness.pdf"><img class="section__img-pic" src="../assets/img/71.png"></a>
              <div class="section__content">
                <div class="section__text">В 2020 году ООО “НПЦ МТ “Армед” получило положительный ответ от ФБУН НИИ Дезинфектологии Роспотребнадзора на запрос об эффективности рециркуляторов в профилактике заболеваний, вызванных коронавирусом, другими вирусами и бактериями. Она составила 98.3% !</div>
              </div>
            </div>
          </div>
        </section>
        <section class="section section4">
          <div class="container">
            <h2 class="section__title">Механические кровати</h2>
            <div class="section__row">
              <div class="section__content">
                <div class="section__subtitle">Армед РС106-Б</div>
                <div class="options__list">
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Удобная механическая регулировка головной, тазобедренной и голеностопной секций</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Складные рычаги управления</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Грузоподъемность – до 250 кг</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Складные металлические боковые ограждения с надежными фиксаторами</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Стойка для подтягивания и инфузионная стойка в комплекте</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Маневренные колеса с тормозными педалями</div>
                  </div>
                </div>
              </div>
              <div class="section__img"><img class="section__img-pic" src="../assets/img/81_n.png"></div>
            </div>
          </div>
        </section>
        <section class="section section5 reverse">
          <div class="container">
            <h2 class="section__title">В чем сила НПЦ "Армед"?"</h2>
          </div>
          <div class="container">
            <div class="section__row">
              <div class="section__content">
                <div class="section__subtitle">Сертифицированное оборудование</div>
                <div class="section__text">Все товары, которые мы производим, проходят обязательную сертификацию, имеют регистрационные удостоверения от Росздравнадзора</div>
              </div>
              <div class="section__img"><img class="section__img-pic" src="../assets/img/92.png"></div>
            </div>
          </div>
        </section>
        <section class="section section7">
          <div class="container">
            <div class="section__subtitle">Большой прорыв: компания Армед получила сертификат ИСО 13485</div>
            <div class="section__col">
              <div class="section__content">
                <div class="section__text">Еще один веский повод для гордости - мы получили сертификат ИСО 13485, международный стандарт, разработанный Международной организацией по стандартизации. Сертификации подлежат товары, на которых мы специализируемся: рециркуляторы, кислородные концентраторы, медицинские кровати и матрасы, светильники, средства для реабилитации, отсасыватели. Теперь мы можем с гордостью сказать: товары Армед - это высокопрофессиональное оборудование, разработанное по международным стандартам качествам.</div>
              </div>
              <div class="section__img"><img class="section__img-pic" src="../assets/img/1000_n.png"></div>
            </div>
          </div>
        </section>
        <section class="section section4 reverse">
          <div class="container">
            <h2 class="section__title">Сотрудничество с нами</h2>
            <div class="section__text">Развивайтесь, стройте бизнес, оснащайте больницы и другие медицинские учреждения вместе с НПЦ МТ “Армед”</div>
            <div class="section__list">
              <div class="section__item min-shadow">
                <div class="section__info">
                  <div class="section__info-decor"><img class="section__card-decor-pic" src="../assets/img/101.png"></div>
                  <div class="section__info-text"><span>С нами уже сотрудничают</span> более 11 000 медицинских учреждений и сотни торговых компаний</div>
                </div>
              </div>
              <div class="section__item min-shadow">
                <div class="section__info">
                  <div class="section__info-decor"><img class="section__card-decor-pic" src="../assets/img/102.png"></div>
                  <div class="section__info-text"><span>Наш бренд ассоциируется</span> у покупателей с высоким качеством изделий и доступными ценами</div>
                </div>
              </div>
              <div class="section__item min-shadow">
                <div class="section__info">
                  <div class="section__info-decor"><img class="section__card-decor-pic" src="../assets/img/103.png"></div>
                  <div class="section__info-text"><span>Мы остаемся клиентоориентированной компанией</span> даже в текущей ситуации нестабильности</div>
                </div>
              </div>
            </div>
            <div class="section__row">
              <div class="section__img">
                <img class="section__img-pic" src="../assets/img/111_n.png">
                <img class="section__img-pic" src="../assets/img/112_n.png">
              </div>
              <div class="section__content">
                <div class="section__subtitle">Для наших партнеров:</div>
                <div class="options__list">
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Гибкая система скидок</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Производство и отгрузка в поставленные сроки</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Собственный автопарк и услуги транспортных компаний</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Доставка заказов по всей России и в страны СНГ</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Самовывоз со склада в Московской области</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Бесплатное обучение сотрудников</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Рекламная поддержка в медиа-сфере</div>
                  </div>
                  <div class="options__item"><img class="options__item-icon" src="../assets/img/icon.png">
                    <div class="options__item-text">Информационная и рекламная печатная продукция</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section section6">
          <div class="container">
            <h2 class="section__title">Бренд, которому доверяют</h2>
            <div class="section__list"><a class="section__link" href="https://www.armed.ru"><img class="section__link-img" src="../assets/img/122.jpg"></a></div>
            <div class="section__text">Рекомендуем не откладывать решение и обсудить условия сотрудничества уже сегодня. Свяжитесь с нашим менеджером по телефону <a href="tel:84956362825">8 (495) 636-28-25</a>, и мы обговорим все интересующие вопросы</div>
          </div>
        </section>
        <section class="section section8">
          <ProvidersNPC />
        </section>
      </main>
</template>

<script>
import ProvidersNPC from './ProvidersNPC.vue';
import axios from 'axios';

export default {
  name: 'MainIndex',
  components: {
    ProvidersNPC
  },
  data: () => ({
    products: [],
    isUserMobile: false
  }),
  created() {
    this.getProducts();
    this.isUserMobile = this.isMobile();
  },
  methods: {
    getProducts() {
      axios
        .get('https://www.armed.ru/personal/npcarmed/export-products/?ids=58524,58523,27540,27538,15345,15344,15343,15342,15341,15340')
        .then((response) => {
          let product_arrived = response.data;

          this.products = product_arrived;
        });
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
