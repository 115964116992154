<template>
    <div class="wrapper">
      <header class="header">
        <div class="container">
          <div class="header__row">
            <router-link @click.prevent="scrollToTop" to="/" class="header__logo">
              <img class="header__logo-img" src="./assets/img/logo.png" alt="НПЦ МТ Армед">
            </router-link>
            <div class="header__row-right">
              <router-link @click.prevent="scrollToTop" to="/vacancy" class="header__phone link">Вакансии</router-link>
              <a class="header__phone" href="tel:+74951502898">8 (495) 150 28 98</a>
            </div>
          </div>
        </div>
      </header>
      <router-view></router-view>
      <footer class="footer">
        <div class="container">
          <div class="footer__row">
            <div class="footer__col">
              <div class="footer__col-title">Реквизиты</div>
              <ul class="footer__list">
                <li class="footer__item"><span>ООО «Научно-производственный центр медицинской техники «АРМЕД»</span></li>
                <li class="footer__item"><span>Юридический адрес:</span> 630091, Новосибирская область, г.о. город Новосибирск, г. Новосибирск, ул. Фрунзе, д. 5, этаж 4, офис 406</li>
                <li class="footer__item"><span>Фактический адрес:</span> 143912, Московская область, г. Балашиха, шоссе Энтузиастов Западная коммунальная зона, владение 1А</li>
                <li class="footer__item"><span>Адрес производства:</span> 601670, Владимирская область, г. Струнино, Александровский район, улица Асафа Баранова, 10</li>
                <li class="footer__item"><span>ИНН/КПП:</span> 5001092469 / 540601001</li>
                <li class="footer__item"><span>ОГРН:</span> 1135001000617</li>
                <li class="footer__item"><span>ОКПО:</span> 13391002, <span>ОКАТО:</span> 46204501000, <span>ОКВЭД:</span> 32.50</li>
                <li class="footer__item"><span>Р/С:</span> 40702810900000011480 в ПАО «Промсвязьбанк» г. Москва</li>
                <li class="footer__item"><span>БИК:</span> 044525555, <span>К/С:</span> 30101810400000000555</li>
                <li class="footer__item"><span>Ген. директор:</span> Щукарев Андрей Анатольевич</li>
                <li class="footer__item"><span>Гл.бухгалтер:</span> Боровикова Анастасия Викторовна</li>
              </ul>
            </div>
            <div class="footer__col">
              <div class="footer__col-title">Контакты</div>
              <ul class="footer__list">
                <li class="footer__item"><a href="tel:+74951502898">+7 (495) 150 28 98</a></li>
                <li class="footer__item"><a href="mailto:info@npcarmed.ru">info@npcarmed.ru</a></li>
                <li class="footer__item">143912, Московская область, г. Балашиха, шоссе Энтузиастов Западная коммунальная зона, владение 1А, пом. 1/1</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({

  }),
  created() {

  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
    scrollToTop() {
      window.scrollTo(({
        top: 0,
        behavior: 'smooth',
      }));
    }
  }
}
</script>